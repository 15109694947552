import { render, staticRenderFns } from "./CookieConsent.vue?vue&type=template&id=bd797a14&scoped=true"
import script from "./CookieConsent.vue?vue&type=script&lang=js"
export * from "./CookieConsent.vue?vue&type=script&lang=js"
import style0 from "./CookieConsent.vue?vue&type=style&index=0&id=bd797a14&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bd797a14",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LinkElement: require('/build/src/components/elements/LinkElement.vue').default,Button: require('/build/src/components/elements/Button.vue').default,VMIcon: require('/build/src/components/elements/VMIcon.vue').default})
